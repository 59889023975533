import classNames from "classnames";
import React, { FC, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import {
  gameBgZIndex, gameCoverEndpoint, gameCoverRatio, imageBgOverlayZIndex,
  userGameContainerWidth,
  userGameCoverWidth, userGameCoverWidthSmall
} from "../utils/constants";
import { GameType, PlaythroughType, UserGameType } from "../utils/types";
import { BadgesPanel } from "./badges-panel";
import CardBackground from "./card-background";
import { Cover } from "./cover";
import GameOverlay from "./game-overlay";
import ReviewBadge from "./review-badge";
import UserGameHeader from "./user-game-header";
import UserGamePlaythrough from "./user-game-playthrough";

const UserGame:
    FC<{userGame?: UserGameType, gameData: GameType, isMobile?: boolean, hoverable?: boolean, fixedWidth?: number,
        coverBackground?: boolean, basePlaythrough?: PlaythroughType, onListView?: boolean, roundedBottom?: boolean,
        setModalData?: (gameData: UserGameType) => void}> =
    ({ userGame, gameData, isMobile, hoverable= true, setModalData,
      fixedWidth, coverBackground =false, basePlaythrough, onListView, roundedBottom= true  }) => {
      const { variant } = React.useContext(VariantContext);

      const ref = useRef<HTMLDivElement>(null);
      const [searchParams] = useSearchParams();
      const userGameId = searchParams.get("id");

      const { ref: inViewRef, inView } = useInView({
        triggerOnce: true, // Load only once
        threshold: 0.1, // Trigger when 10% is visible
      });

      useEffect(() => {
        if(userGameId && parseFloat(userGameId) === userGame?.id && ref && setModalData){
          ref?.current?.scrollIntoView({ block: "center",
            behavior: "instant" as ScrollBehavior });
          setModalData(userGame);
        }
      }, []);

      const allPlaythroughs = userGame?.playthroughs ?? [];
      const completed = allPlaythroughs.filter((playthrough)=>
        playthrough.completed).length > 0;

      const childGame = allPlaythroughs.filter((playthrough)=>
        playthrough.type === "kid").length > 0;

      return (
        <div
          ref={inViewRef}
          style={{ minHeight: inView
            ? undefined
            :  460 }}
        >
          <div
            ref={ref}
            className={classNames("position-relative", "overflow-hidden", "h-100",
              { "list-card": hoverable },
              { "m-0": onListView },
              { "rounded-bottom-3": roundedBottom && onListView },
              { "rounded-2 m-3": !onListView },
              { "bg-secondary": !coverBackground })}
            style={{ width: fixedWidth }}>

            {inView && <>
              <GameOverlay
                visible={coverBackground}
                opacity={colors[variant.theme as keyof typeof colors].imageBgOverlayOpacity}
                color={colors[variant.theme as keyof typeof colors].bgOverlay}
                zIndex={imageBgOverlayZIndex}
              />
              <CardBackground
                endpoint={gameCoverEndpoint}
                visible={coverBackground}
                id={gameData.id}
                zIndex={gameBgZIndex}/>

              <div>
                {/*            {isMobile &&
            <div className={"text-center p-3 pb-4"}>
              <span className={"fw-bold fs-5"}>
                {gameData.name}
              </span>
            </div>}*/}
                <div
                  className={"d-flex"}>
                  {inView && <Cover
                    ratio={gameCoverRatio}
                    endpoint={gameCoverEndpoint}
                    width={isMobile
                      ? userGameCoverWidthSmall
                      : userGameCoverWidth}
                    id={gameData.id}/>}

                  <UserGameHeader
                    hideLastHr={!userGame}
                    isMobile={isMobile}
                    game={gameData}/>
                </div>

                {userGame &&
                <div className={"mt-3 mb-3 me-2 d-flex justify-content-between"}>
                  <ReviewBadge
                    review={userGame.reviews[0]}
                    showCount={true}
                    reviewCount={userGame.reviews.length}/>

                  <BadgesPanel
                    isGame={true}
                    completed={completed}
                    childhood={childGame}
                    achieved={userGame.achieved}
                    favouriteMusic={userGame.favouriteMusic}
                    favourite={userGame.favourite}
                    platinum={userGame.platinum}/>
                </div>
                }
              </div>

              {basePlaythrough &&
              <UserGamePlaythrough
                playthrough={basePlaythrough}
                playthroughCount={allPlaythroughs.length}
                previewFlag/>}
            </>}

          </div>

        </div>

      );
    };

export default UserGame;