import React, { FC, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { bigScreenBreakpoint, clientAddress, hostAddress } from "../utils/constants";
import { ReviewType } from "../utils/types";
import { BadgesPanel } from "./badges-panel";
import ReviewBadge from "./review-badge";
import UserReview from "./user-review";
import InteractiveIcon from "./utils/interactive-icon";


const DetailsModalFooter : FC<{reviews: ReviewType[], achieved?: boolean,
    favouriteMusic?: boolean, favourite?: boolean,
    platinum?: boolean, completed?: boolean,
    childhood?: boolean, isGame: boolean, shareId: number }> =
    ({ reviews, platinum, favourite, favouriteMusic,
      achieved, childhood, isGame, shareId, completed  }) => {

      const storageUser = localStorage.getItem("user");
      const isMobile = !useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
      const shareUrl = `${clientAddress}/films?u=${storageUser === "shichi7"
        ? "s"
        : "w"}&id=${shareId}`;

      const [showLinkFlag, setShowLinkFlag] = useState(false);
    
      const [activeReview, setActiveReview] = useState<number|undefined>(reviews.length > 0
        ? 0
        : undefined);

      return (
        <>
          <div className={"d-flex justify-content-between align-items-center pt-0 pb-0 p-2 mt-2 mb-2"}>

            <div className={"d-flex"}>
              {reviews.map((review, index) =>
                (
                  <ReviewBadge
                    index={index}
                    setActiveReview={setActiveReview}
                    key={review.id}
                    review={review}
                    reviewCount={reviews.length}
                    active={activeReview === index}
                    showDate/>
                ))}

              {reviews?.length === 0 &&
              <span className={"ms-2 p-2 rounded-2 bg-secondary"}>
                Brak opinii
              </span>}
            </div>
            <div className={"gap-2 d-flex align-items-center"}>

              <div className={"p-2 border-secondary border-end"}>
                <BadgesPanel
                  achieved={achieved}
                  favouriteMusic={favouriteMusic}
                  favourite={favourite}
                  platinum={platinum}
                  completed={completed}
                  childhood={childhood}
                  isGame={isGame}/>
              </div>
              {showLinkFlag &&
                <div>
                  <span
                    style={{ cursor: "pointer" }}
                    className={"badge bg-secondary"}>
                    {shareUrl}
                  </span>
                </div>
              }

              <div className={"me-2"}>
                <InteractiveIcon
                  className={""}
                  onClick={() => {
                    navigator.clipboard.writeText(`${shareUrl}`);
                    !isMobile && setShowLinkFlag(!showLinkFlag);
                  }}
                  iconName={"fas fa-link"}></InteractiveIcon>
              </div>
            </div>
          </div>

          {activeReview !== undefined && (reviews[activeReview].entries.length > 0) &&
            <UserReview review={reviews[activeReview]}/>
          }
        </>);
    };

export default DetailsModalFooter;