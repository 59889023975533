import classNames from "classnames";
import React, { FC, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";

import useDetailsHistory from "../hooks/use-details-history";
import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import {
  filmCoverEndpoint, filmCoverRatio,
  gameBgZIndex,
  imageBgOverlayZIndex,
  userGameCoverWidth, userGameCoverWidthSmall,
} from "../utils/constants";
import { FilmType, UserFilmType, UserGameType, WatchthroughType } from "../utils/types";
import { BadgesPanel } from "./badges-panel";
import CardBackground from "./card-background";
import { Cover } from "./cover";
import GameOverlay from "./game-overlay";
import ReviewBadge from "./review-badge";
import { UserFilmHeader } from "./user-film-header";
import { UserFilmWatchthrough } from "./user-film-watchthrough";

export const UserFilm:
    FC<{userFilm?: UserFilmType, filmData: FilmType, isMobile?: boolean, hoverable?: boolean, fixedWidth?: number,
        coverBackground?: boolean, baseWatchthrough?: WatchthroughType, onListView?: boolean,
        roundedBottom?: boolean,
        setModalData?: (filmData: UserFilmType) => void}> =
    ({ userFilm,isMobile, hoverable
      , fixedWidth, filmData,  coverBackground =false, baseWatchthrough,
      onListView, roundedBottom= true, setModalData   }) => {

      const { variant } = React.useContext(VariantContext);

      const ref = useRef<HTMLDivElement>(null);
      const [searchParams] = useSearchParams();
      const userFilmId = searchParams.get("id");

      const { ref: inViewRef, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
      });

      useEffect(() => {
        if(userFilmId && parseFloat(userFilmId) === userFilm?.id && ref && setModalData){
          ref?.current?.scrollIntoView({ block: "center",
            behavior: "instant" as ScrollBehavior });
          setModalData(userFilm);
        }
      }, []);


      const allWatchthroughs = userFilm?.watchthroughs ?? [];
      const completed = allWatchthroughs.filter((watchthrough)=>
        watchthrough.completed).length > 0;

      return (
        <div
          ref={inViewRef}
          style={{ minHeight: inView
            ? undefined
            :  460 }}
        >
          <div
            ref={ref}
            className={classNames("position-relative", "overflow-hidden", "h-100",
              { "list-card": hoverable },
              { "m-0": onListView },
              { "rounded-bottom-3": roundedBottom && onListView },
              { "rounded-2 m-3": !onListView },
              { "bg-secondary": !coverBackground })}
            style={{ width: fixedWidth }}>

            {inView && <>
              <GameOverlay
                visible={coverBackground}
                opacity={colors[variant.theme as keyof typeof colors].imageBgOverlayOpacity}
                color={colors[variant.theme as keyof typeof colors].bgOverlay}
                zIndex={imageBgOverlayZIndex}
              />
              <CardBackground
                endpoint={filmCoverEndpoint}
                visible={coverBackground}
                id = {filmData.id}
                zIndex={gameBgZIndex}/>

              <div>
                {/*            {isMobile &&
              <div className={"text-center p-3 pb-4"}>
                <span className={"fw-bold fs-5"}>
                  {mainTitle}
                </span>
              </div>}*/}
                <div
                  className={"d-flex"}>
                  <Cover
                    ratio={filmCoverRatio}
                    endpoint={filmCoverEndpoint}
                    width={isMobile
                      ? userGameCoverWidthSmall
                      : userGameCoverWidth}
                    id={filmData.id}/>
                  <UserFilmHeader
                    hideLastHr={!userFilm}
                    isMobile = {isMobile}
                    film={filmData}/>
                </div>

                {userFilm &&
                <div className={"mt-3 mb-3 me-2 d-flex justify-content-between"}>
                  <ReviewBadge
                    review={userFilm.reviews[0]}
                    showCount={true}
                    reviewCount={userFilm.reviews.length}/>

                  <BadgesPanel
                    isGame={false}
                    completed={completed}
                    favourite={userFilm.favourite}
                    childhood={userFilm.childhood}
                    favouriteMusic={userFilm.favouriteMusic}/>
                </div>}

              </div>

              {baseWatchthrough &&
              <UserFilmWatchthrough
                watchthrough={baseWatchthrough}
                count={allWatchthroughs.length}
                previewFlag/>}
            </>}
          </div>
        </div>
      );
    };