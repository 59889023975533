import "./layout.scss";

import FancyText from "@carefully-coded/react-text-gradient";
import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";

import ToggleAction from "../components/utils/toggle-action";
import { Themes } from "../utils/colors";
import { defaultVariant } from "../utils/constants";
import { RoutePaths } from "../utils/routes";
import VariantContext from "./context/variantContext";
import NavigationIcon from "./navigation-icon";

const DarkTheme = React.lazy(() =>
  import("./themes/theme-dark"));

const GreyTheme = React.lazy(() =>
  import("./themes/theme-grey"));

const Layout = () => {
  const [searchParams] = useSearchParams();
  const storageUser = localStorage.getItem("user");

  let paramUser = searchParams.get("u");

  if(paramUser === "w"){
    paramUser = "wafelek";
  }else if(paramUser === "s"){
    paramUser = "shichi7";
  }else{
    paramUser = null;
  }

  const defaultUser = paramUser ?? storageUser ?? "shichi7";

  const [variant, setVariant ] = useState(defaultVariant);
  const [animateHeader, setAnimateHeader ] = useState(false);
  const { theme, advancedBgs } = variant;
  const location = useLocation();

  /*  const [theme, setTheme] = useState(Themes.Dark);*/
  const [reloaded, setReloaded] = useState(true);
  const [user, setUser]
      = useState(defaultUser);

  useEffect(() => {
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
  }, []);

  useEffect(() => {
    setReloaded(false);
    localStorage.setItem("user", user);
    if (!reloaded){
      window.location.replace(location.pathname);
      //window.location.reload();
    }
  }, [user]);

  const userGamesViewFlag = RoutePaths.GAMES_HOME_ROUTE === location.pathname;
  const userFilmsViewFlag = RoutePaths.FILMS_HOME_ROUTE === location.pathname
      || RoutePaths.CINEMA_ROUTE === location.pathname || location.pathname === "/";

  useEffect(() => {
    setReloaded(false);
    if (userGamesViewFlag){
      setVariant({ ...variant,
        theme: Themes.Dark });
      if (!reloaded){
        window.location.replace(location.pathname);
        //window.location.reload();
      }
    }

    if (userFilmsViewFlag){
      setVariant({ ...variant,
        theme: Themes.Grey });
      if (!reloaded){
        window.location.replace(location.pathname);
        //window.location.reload();
      }
    }
  }, [location]);

  return (
    <>
      <React.Suspense
        key={theme}
        fallback={<></>}>
        {theme === Themes.Grey && <GreyTheme />}
        {theme === Themes.Dark && <DarkTheme />}
        <div className={"mb-5"}>
          <div
            style={{ zIndex: 100 }}
            className='ps-3 pe-3 d-flex text-white sticky-top
             justify-content-between bg-primary align-items-center'>
            <Link
              to={"/"}
              className={"w-100"}>
              <div className={"w-100"}>
                {animateHeader
                  ?    <FancyText
                    style={{ fontWeight: 1000,
                      cursor: "pointer" }}
                    className={"fs-3"}
                    gradient={{ from: "#ffffff",
                      to: "#ffffff",
                      type: "linear" }}
                    animateTo={{ from: "#F858E0",
                      to: "#00ffff" }}
                    animate
                    animateDuration={1500}
                  >
                    <div className={"d-flex align-items-center"}>
                      <span className={"fas fa-soap me-2 fs-5"}/>
                      StoryClicks
                    </div>
                  </FancyText>
                  :
                  <FancyText
                    style={{ fontWeight: 1000,
                      cursor: "pointer" }}
                    onMouseEnter={() =>
                      setAnimateHeader(true)}
                    gradient={{ from: "#ffffff",
                      to: "#ffffff",
                      type: "linear" }}
                    className={"fs-3"}
                  >
                    <div className={"d-flex align-items-center"}>
                      <span className={"fas fa-soap me-2 fs-5"}/>
                      StoryClicks
                    </div>
                  </FancyText>}
              </div>
            </Link>

            <MediaQuery query={"(max-width: 800px)"}>
              <div className={"d-flex justify-content-center align-items-center bg-primary"}>
                <NavigationIcon
                  small
                  active={userGamesViewFlag}
                  to={RoutePaths.GAMES_HOME_ROUTE}
                  icon="gamepad"/>
                <NavigationIcon
                  small
                  active={userFilmsViewFlag}
                  to={RoutePaths.FILMS_HOME_ROUTE}
                  icon="video"/>
                {/*                <NavigationIcon
                  small
                  to={RoutePaths.LISTS_ROUTE}
                  icon="layer-group"/>*/}
                {/*                <NavigationIcon
                  small
                  to={RoutePaths.CINEMA_ROUTE}
                  icon="clapperboard"/>*/}
                <div
                  className={"ps-2 pb-1"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const newUser = user === "shichi7"
                      ? "wafelek"
                      : "shichi7";
                    setUser(newUser);
                  }}>
                  <span className={"badge bg-secondary fw-light"}>{user.substring(0, 1).toUpperCase()}</span>
                </div>
              </div>
            </MediaQuery>

            <MediaQuery query="(min-width: 800px)">
              <div className={"w-100 d-flex justify-content-center bg-primary"}>
                <NavigationIcon
                  active={userGamesViewFlag}
                  to={RoutePaths.GAMES_HOME_ROUTE}
                  icon="gamepad"/>
                <NavigationIcon
                  active={userFilmsViewFlag}
                  to={RoutePaths.FILMS_HOME_ROUTE}
                  icon="video"/>
                {/*                <NavigationIcon
                  to={RoutePaths.LISTS_ROUTE}
                  icon="layer-group"/>*/}
                {/*                <NavigationIcon
                  to={RoutePaths.CINEMA_ROUTE}
                  icon="clapperboard"/>*/}
              </div>
              <div
                className={"w-100 d-flex justify-content-end align-items-center"}>
                {/*                <ToggleAction
                  icon={"wand-magic-sparkles"}
                  value={advancedBgs}
                  action={(value)=>
                    setVariant({ ...variant,
                      advancedBgs: value })
                  }/>*/}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={()=> {
                    const newUser = user === "shichi7"
                      ? "wafelek"
                      : "shichi7";
                    setUser(newUser);
                  }}>
                  <span className={"badge bg-secondary fw-light"}>{user}</span>
                </div>
              </div>
            </MediaQuery>


          </div>
          <VariantContext.Provider
            value={{ variant,
              setVariant }}>
            <Outlet/>
          </VariantContext.Provider>
        </div>
      </React.Suspense>
    </>
  );
};
export default Layout;