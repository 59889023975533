import React, { FC, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";

import VariantContext from "../layout/context/variantContext";
import {
  bigScreenBreakpoint,
  gameCoverEndpoint,
  gameCoverRatio,
  userGameContainerWidth
} from "../utils/constants";
import { UserGameSetType, UserGameType } from "../utils/types";
import { Cover } from "./cover";
import UserGame from "./user-game";
import { UserSetHeader } from "./user-set-header";

const UserGameSet: FC<{gameSet: UserGameSetType, coverAttack: boolean,
    setModalData: (gameData: UserGameType) => void}> = ({ gameSet, setModalData, coverAttack }) => {
      const { variant } = React.useContext(VariantContext);
      const { advancedBgs, labeled } = variant;
      const { label, entries } = gameSet;

      const [setExtended, setSetExtended] = useState(true);

      const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
      return (
        <div>
          {entries.length > 0 && (labeled ?
            <UserSetHeader
              count={entries.length}
              setExtended={setExtended}
              toggleSetExtended={setSetExtended}
              label={label}
              theme={variant.theme}/>
            :
            "")}

          {setExtended &&
            <div
              className='
            mt-2
         d-flex
         align-items-center
         flex-wrap
         justify-content-center'>
              {
                entries.map((entry, index)=>
                  (
                    <div
                      className={`${isBigScreen || coverAttack
                        ? ""
                        : "w-100"}`}
                      onClick={
                        () =>  
                          setModalData(entry.gameData)
                      }
                      key={`entry-${entry.playthroughData.id}`}>
                      {!coverAttack ?
                        <UserGame
                          setModalData={setModalData}
                          gameData={entry.gameData.game}
                          isMobile = {!isBigScreen}
                          fixedWidth={isBigScreen
                            ? userGameContainerWidth
                            : undefined}
                          userGame={entry.gameData}
                          coverBackground={advancedBgs}
                          basePlaythrough={entry.playthroughData}/> :

                        <div className={"p-2"}>
                          <Cover
                            endpoint={gameCoverEndpoint}
                            id = {entry.gameData.game.id}
                            width={100}
                            ratio={gameCoverRatio}/>
                        </div>
                      }
                    </div>
                  ))
              }
            </div> }
        </div>
      );
    };

export default UserGameSet;